
import { TheMask } from 'vue-the-mask'
import { Component, Prop, Ref } from 'nuxt-property-decorator'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import DocGrz from '@pp-frontend/shared-packages/classes/doc-value/DocGrz'
import GrzGibddSearch from '@pp-frontend/shared-packages/classes/landing/gibdd/GrzGibddSearch'
import LicencePlateInput from '../LicencePlateInput.vue'
import LicencePlateInputMotorbike from '../LicencePlateInputMotorbike.vue'
import LicencePlateInputAnotherTs from '../LicencePlateInputAnotherTs.vue'
import InfoBtn from './InfoBtn.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchEmailField from '~/components/search-form/SearchEmailField.vue'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'

@Component({
  components: {
  SearchFormOfertaText,
  SearchEmailField,
  InfoBtn,
  TheMask,
  LicencePlateInput,
  LicencePlateInputMotorbike,
  LicencePlateInputAnotherTs
  }
  })
export default class extends SearchFormAbstract {
  @Prop({ default: true }) showLicencePlate!: boolean
  @Ref() ctcField!: HTMLInputElement
  @Prop() dataErrorFormValidations!: ErrorValidation

  USER_EMAIL_REQUIRED = true
  clientErrorValid: string = ''
  errorCode = 0
  showSubmitBtnSpinner: boolean = false
  formData = new DocGrz()
  showTip: boolean = false
  grzTypeInput: string = 'avto'
  grzTypes: string[] = ['avto', 'motorbike', 'another']

  changeGrzTypeInput (type: number) {
    this.$v.$reset()
    this.showTip = false
    this.grzTypeInput = this.grzTypes[type]
  }

  errorClientValid () {
    this.showTip = false
    this.clientErrorValid = ''
    this.$v.$touch()

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.document_value.$error) {
        this.showTip = true
        this.clientErrorValid = 'гос. номеров: <span class="error-msg__item">A 000 AA 000</span> <span class="error-msg__item">A 000 AA 00</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }

  afterSubmitBehavior (): Promise<any> {
    this.showSubmitBtnSpinner = true
    return new GrzGibddSearch().validate(this.formData).then(() => {
      this.errorCode = 0
      return this.$router.push({
        path: '/search/' + this.getType(),
        query: {
          document_type: this.formData.docType(),
          lead_source_id: window.$nuxt.$route.query.lead_source_id,
          ...this.formData
        }
      })
    }).catch((err) => {
      this.showSubmitBtnSpinner = false
      if (err.response.status === 429) {
        this.$route.query.grzblock = 'true'
      }
      this.errorCode = err.response.status
    })
  }
}
