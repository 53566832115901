var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input-group bordered custom-v-popper-grz",class:{ 'input-error-grz': _vm.isError }},[_c('div',{staticClass:"car-number"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.plateNum),expression:"plateNum"},{name:"mask",rawName:"v-mask",value:(_vm.plateNumMask),expression:"plateNumMask"},{name:"tooltip",rawName:"v-tooltip.top",value:({
        html:true,
        theme:'info-text',
        distance:8,
        content:`
          <p>Формат номера:</p>
          <span style='color: #EE6F57;'>А 000 АА 00, AA 000 00,</span><br>
          <span style='color: #EE6F57;'>0000 АА 00 , 000 АА 00</span>
          <br>
          <br>
          <p>Допустимые символы для поля:</p>
          <ul>
            <li>цифры - <span style='color: #EE6F57;'>0-9</span>;</li>
            <li>буквы - <span style='color: #EE6F57;'>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>
          </ul>
          <p style='font-weight: 600;'>Если вы хотите проверить мотоцикл или другое ТС, нажмите на соответствующую кнопку ниже</p>
        `,
        triggers: [],
        shown: _vm.isTooltipShown || _vm.isError,
        container: '.custom-v-popper-grz'
      }),expression:"{\n        html:true,\n        theme:'info-text',\n        distance:8,\n        content:`\n          <p>Формат номера:</p>\n          <span style='color: #EE6F57;'>А 000 АА 00, AA 000 00,</span><br>\n          <span style='color: #EE6F57;'>0000 АА 00 , 000 АА 00</span>\n          <br>\n          <br>\n          <p>Допустимые символы для поля:</p>\n          <ul>\n            <li>цифры - <span style='color: #EE6F57;'>0-9</span>;</li>\n            <li>буквы - <span style='color: #EE6F57;'>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>\n          </ul>\n          <p style='font-weight: 600;'>Если вы хотите проверить мотоцикл или другое ТС, нажмите на соответствующую кнопку ниже</p>\n        `,\n        triggers: [],\n        shown: isTooltipShown || isError,\n        container: '.custom-v-popper-grz'\n      }",modifiers:{"top":true}}],staticClass:"form-control",attrs:{"id":_vm.numId,"type":"text","placeholder":"Укажите номер ТС"},domProps:{"value":(_vm.plateNum)},on:{"input":[function($event){if($event.target.composing)return;_vm.plateNum=$event.target.value},function($event){_vm.$emit('input', $event.target.value.replace(/\s+/g, '') + _vm.plateReg)}],"keypress":(e) => {!e.key.match(_vm.plateNumPatternWithNums) ? _vm.isTooltipShown = true : _vm.isTooltipShown = false},"blur":function($event){_vm.isTooltipShown = false}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }